.kill-history {
	color: #cacaca;
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
}
.kill-history-table {
	/* min-width: 700px; */
}
.kill-history-table-cell {
	/* text-align: center !important; */
	color: #cacaca !important;
	padding: 0 0 0 !important;
	font-size: 14px !important;
	border-bottom: 0 !important;
	border-top: 1px solid #4a4949 !important;
}
.kill-history-player-head {
	width: 32px;
	vertical-align: middle;
}
.kill-history-link {
	text-decoration: none;
}

* {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
		Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	box-sizing: border-box;
}
body {
	background-color: #2f3136;
	color: #cacaca;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.1;
}
.white {
	color: white !important;
}
.red {
	color: crimson;
}
ul {
	padding-left: 0px;
}
.underline {
	text-decoration: underline;
}
.no-text-decoration {
	text-decoration: none;
}
.no-padding-margin {
	padding: 0;
	margin: 0;
}
.bold {
	font-weight: bold;
}
.page {
	margin-top: 60px;
	top: 0;
	left: 0;
	width: 100%;
}
a {
	text-decoration: none;
	color: inherit;
}

.vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

/* We need to wrap the site with flexbox to make sure the footer is always at the bottom of the page */
.flex-wrapper {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	justify-content: space-between;
}

.page,
.footer,
.nav {
	font-size: larger;
}

.alert {
	font-size: medium !important;
}

.ss-dl {
	color: #cacaca;
}

/**
*** SIMPLE GRID
*** (C) ZACH COLE 2016
**/

/* UNIVERSAL */

html,
body {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	left: 0;
	top: 0;
	font-size: 100%;
}

/* POSITIONING */

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.center {
	text-align: center !important;
	margin-left: auto;
	margin-right: auto;
}

.justify {
	text-align: justify;
}

/* ==== GRID SYSTEM ==== */

.container {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
}

.row {
	position: relative;
	width: 100%;
}

.row [class^='col'] {
	float: left;
	margin: 0.5rem 2%;
	min-height: 0.125rem;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
	width: 96%;
}

.col-1-sm {
	width: 4.33%;
}

.col-2-sm {
	width: 12.66%;
}

.col-3-sm {
	width: 21%;
}

.col-4-sm {
	width: 29.33%;
}

.col-5-sm {
	width: 37.66%;
}

.col-6-sm {
	width: 46%;
}

.col-7-sm {
	width: 54.33%;
}

.col-8-sm {
	width: 62.66%;
}

.col-9-sm {
	width: 71%;
}

.col-10-sm {
	width: 79.33%;
}

.col-11-sm {
	width: 87.66%;
}

.col-12-sm {
	width: 96%;
}

.row::after {
	content: '';
	display: table;
	clear: both;
}

.hidden-sm {
	display: none;
}

@media only screen and (min-width: 33.75em) {
	/* 540px */
	.container {
		width: 80%;
	}
}

@media only screen and (min-width: 45em) {
	/* 720px */
	.col-1 {
		width: 4.33%;
	}

	.col-2 {
		width: 12.66%;
	}

	.col-3 {
		width: 21%;
	}

	.col-4 {
		width: 29.33%;
	}

	.col-5 {
		width: 37.66%;
	}

	.col-6 {
		width: 46%;
	}

	.col-7 {
		width: 54.33%;
	}

	.col-8 {
		width: 62.66%;
	}

	.col-9 {
		width: 71%;
	}

	.col-10 {
		width: 79.33%;
	}

	.col-11 {
		width: 87.66%;
	}

	.col-12 {
		width: 96%;
	}

	.hidden-sm {
		display: block;
	}
}

@media only screen and (min-width: 60em) {
	/* 960px */
	.container {
		width: 75%;
		max-width: 60rem;
	}
}

* {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
		Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	box-sizing: border-box;
}
body {
	background-color: #2f3136;
	color: #cacaca;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.1;
}
.white {
	color: white !important;
}
.red {
	color: crimson;
}
ul {
	padding-left: 0px;
}
.underline {
	text-decoration: underline;
}
.no-text-decoration {
	text-decoration: none;
}
.no-padding-margin {
	padding: 0;
	margin: 0;
}
.bold {
	font-weight: bold;
}
.page {
	margin-top: 60px;
	top: 0;
	left: 0;
	width: 100%;
}
a {
	text-decoration: none;
	color: inherit;
}

.vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}

/* We need to wrap the site with flexbox to make sure the footer is always at the bottom of the page */
.flex-wrapper {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	justify-content: space-between;
}

.page,
.footer,
.nav {
	font-size: larger;
}

.alert {
	font-size: medium !important;
}

.ss-dl {
	color: #cacaca;
}

.home {
	margin-top: -2px;
}

.jumbotron {
	margin: 0;
	min-height: 300px;
	width: 100%;
	margin-bottom: 20px;
	background-position: center;
	background-size: cover;
	background-image: -webkit-gradient(
			linear,
			left top, left bottom,
			from(rgba(123, 123, 123, 0.2)),
			to(rgba(0, 0, 0, 0.2))
		),
		url('/img/jumbotron-bg.webp');
	background-image: linear-gradient(
			rgba(123, 123, 123, 0.2),
			rgba(0, 0, 0, 0.2)
		),
		url('/img/jumbotron-bg.webp');
}

.jumbotron-header {
	font-size: 75px;
	padding-top: 52px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	font-weight: 500;
}
.jumbotron-button-divider {
	width: 15px;
	height: auto;
	display: inline-block;
}

.jumbotron-no-overflow {
	overflow: hidden;
}

.jumbotron-slide-in-right {
	overflow: hidden;
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes jumbotron-slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes jumbotron-slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
@-webkit-keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

.match-history {
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	color: #cacaca;
}
.match-history-table {
	min-width: 700;
}
.match-history-table-cell {
	color: #cacaca !important;
	padding-top: 100 !important;
	font-size: inherit !important;
	border-bottom: 0 !important;
	border-top: 1px solid #4a4949 !important;
    text-transform: none !important;
}
.match-history-table-cell-icon {
	vertical-align: bottom;
}
.match-history-view-button {
	background-color: #9148d3 !important;
	padding-right: 0px;
}

.kill-history {
	color: #cacaca;
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
}
.kill-history-table {
	/* min-width: 700px; */
}
.kill-history-table-cell {
	/* text-align: center !important; */
	color: #cacaca !important;
	padding: 0 0 0 !important;
	font-size: 14px !important;
	border-bottom: 0 !important;
	border-top: 1px solid #4a4949 !important;
}
.kill-history-player-head {
	width: 32px;
	vertical-align: middle;
}
.kill-history-link {
	text-decoration: none;
}

.feature-grid-image {
	width: 100%;
	height: 140px;
	border-radius: 2px;
	-webkit-transition: -webkit-transform 0.2s;
	transition: -webkit-transform 0.2s;
	transition: transform 0.2s;
	transition: transform 0.2s, -webkit-transform 0.2s;
}

.feature-grid-image:hover {
	-webkit-transform: scale(1.03);
	        transform: scale(1.03);
}

.nav-grow {
	flex-grow: 1;
}

.nav {
	background-color: #282a2d !important;
}

.nav-logo {
	height: auto;
	width: 25px;
	float: left;
	margin-right: 10px;
}

.nav-container {
	display: flex;
	align-items: center;
	width: 100%;
}

.nav-button {
	margin-left: auto;
	text-transform: none;
	text-decoration: none;
	margin-right: 20px;
	font-weight: normal;
	outline: none;
}

.nav-link {
	text-decoration: inherit;
	font-size: 24px;
	font-weight: 600;
	outline: none;
}

.leaderboard-table-cell {
	color: #cacaca !important;
	font-size: 14px !important;
	border-bottom: 0 !important;
	border-top: 1px solid #4a4949 !important;
	padding: 8px;
}

.leaderboard-player-head {
	padding-right: 15px !important;
	vertical-align: middle;
}

.leaderboard-rank {
	font-size: 20px !important;
}

.leaderboard > .container > h3 {
	padding-bottom: 2%;
}

.footer {
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #1a1b1d;
	padding-top: 1.5%;
	padding-bottom: 1%;
	margin-top: 5%;
}

.footer-logo-image {
	height: auto;
	width: 40px;
	margin-top: 0px;
}

/* .footer-link {
	text-decoration: none;
	color: inherit;
} */

.footer-link:hover {
	color: white;
}

.footer-header {
	letter-spacing: 0.15em;
	text-transform: uppercase;
}

.footer-logo-wrapper {
	padding-top: 4%;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

/* Animate the Warzone logo */
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.footer > div > div > div > img:hover {
	-webkit-animation: spin 1.5s linear infinite;
	animation: spin 1.5s linear infinite;
}

.play-background {
	background: rgb(47, 49, 54) url('/img/play-bg.webp') center;
	min-height: 100% !important;
	background-size: cover;
	height: 100vh;
	overflow: auto;
	padding-top: 10%;
}
.play {
	margin-top: 0px;
}
.play-footer {
	margin-top: 0px;
}

.play > div > div > h2 > button {
	color: white;
}
.play > div > div > h2 {
	font-weight: 500;
}
.play > div > div > h3 {
	font-weight: 250;
}
.play-highlight {
	background-color: #1e2c35;
	padding: 4px;
}
/* Make it not look like a normal button */
.play-connect-button {
	border: 0;
	background: none repeat scroll 0 0 transparent;
	border: medium none;
	border-spacing: 0;
	color: inherit;
	font-size: 16px;
	font-weight: normal;
	line-height: 1.42rem;
	list-style: none outside none;
	margin: 0;
	padding: 0;
	text-align: left;
	text-decoration: none;
	text-indent: 0;
	cursor: pointer;
	font-weight: bold;
}

.staff-player-image {
	-webkit-transition: -webkit-transform 0.2s;
	transition: -webkit-transform 0.2s;
	transition: transform 0.2s;
	transition: transform 0.2s, -webkit-transform 0.2s;
}

.staff-player-image:hover {
	-webkit-transform: scale(1.03);
	        transform: scale(1.03);
}

.match-info > div > div > div > div > h3 {
	font-weight: 400;
	padding: 0;
	margin: 0;
	margin-top: 2px;
}

.match-info > div > div > div > div > h1 {
	padding: 0;
	margin: 0;
}

.match-info > div > div > div > div:nth-child(3) > h1 {
	float: right;
}

.MuiTableRow-root {
    padding: 10 0;
}
.player-info > div > div > div > div > h3 {
	font-weight: 400;
	padding: 0;
	margin: 0;
	margin-top: 2px;
}

.player-info > div > div > div > div > h1 {
	padding: 0;
	margin: 0;
}

.player-info > div > div > div > div:nth-child(3) > h1 {
	float: right;
	padding-top: 5%;
}

.player-name {
	padding: 2vh !important;
	font-size: 28px;
	vertical-align: middle !important;
	justify-self: center;
	align-self: center;
}

.player-info-stats {
	background-color: #28282a;
	box-shadow: 1px 1px 1px black;
	margin-bottom: 15px;
}

.player-info-stat-title {
	font-size: 15px;
	text-align: center;
}


.jumbotron {
	margin: 0;
	min-height: 300px;
	width: 100%;
	margin-bottom: 20px;
	background-position: center;
	background-size: cover;
	background-image: linear-gradient(
			rgba(123, 123, 123, 0.2),
			rgba(0, 0, 0, 0.2)
		),
		url('/img/jumbotron-bg.webp');
}

.jumbotron-header {
	font-size: 75px;
	padding-top: 52px;
	user-select: none;
	font-weight: 500;
}
.jumbotron-button-divider {
	width: 15px;
	height: auto;
	display: inline-block;
}

.jumbotron-no-overflow {
	overflow: hidden;
}

.jumbotron-slide-in-right {
	overflow: hidden;
	animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes jumbotron-slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

.footer {
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #1a1b1d;
	padding-top: 1.5%;
	padding-bottom: 1%;
	margin-top: 5%;
}

.footer-logo-image {
	height: auto;
	width: 40px;
	margin-top: 0px;
}

/* .footer-link {
	text-decoration: none;
	color: inherit;
} */

.footer-link:hover {
	color: white;
}

.footer-header {
	letter-spacing: 0.15em;
	text-transform: uppercase;
}

.footer-logo-wrapper {
	padding-top: 4%;
	user-select: none;
}

/* Animate the Warzone logo */
@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.footer > div > div > div > img:hover {
	-webkit-animation: spin 1.5s linear infinite;
	-moz-animation: spin 1.5s linear infinite;
	animation: spin 1.5s linear infinite;
}

.match-history {
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	color: #cacaca;
}
.match-history-table {
	min-width: 700;
}
.match-history-table-cell {
	color: #cacaca !important;
	padding-top: 100 !important;
	font-size: inherit !important;
	border-bottom: 0 !important;
	border-top: 1px solid #4a4949 !important;
    text-transform: none !important;
}
.match-history-table-cell-icon {
	vertical-align: bottom;
}
.match-history-view-button {
	background-color: #9148d3 !important;
	padding-right: 0px;
}

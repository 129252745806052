.match-info > div > div > div > div > h3 {
	font-weight: 400;
	padding: 0;
	margin: 0;
	margin-top: 2px;
}

.match-info > div > div > div > div > h1 {
	padding: 0;
	margin: 0;
}

.match-info > div > div > div > div:nth-child(3) > h1 {
	float: right;
}
